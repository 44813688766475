<template>
	<form @keydown.enter="forgotPassword()">
		<h1>Zabudnuté heslo</h1>
		<h5>Zadajte, prosím váš e-mail, s ktorým ste sa registrovali. Následne vám odošleme na e-mail inštrukcie na resetovanie hesla</h5>

		<p class="callout">
			!!! SKONTROLUJTE, či Vám e-mail neprišiel do spamu, koša alebo reklám, prípadne, či Váš školský IT administrátor neblokuje naše e-maily.
		</p>

		<div class="row">
			<div class="form-group col-12" v-if="FEATURE.userEmail">
				<label for="emailAddress">Emailová adresa</label>
				<input v-model="credentials.email" type="email" class="form-control" id="emailAddress" placeholder="Zadajte email"  autocomplete="email">
			</div>
		</div>

		<b-button variant="primary" @click.prevent="forgotPassword()" :disabled="$wToast.isLoading()">Pokračovať</b-button>
	</form>
</template>

<script>
export default {
	props: ['navigateToAfterForgotPassword'],

	data() {
		return {
			credentials: {
				email: ''
			}
		}
	},
	
	methods: {
		async forgotPassword() {
			this.$wToast.clear_loading()

			try {
				await  this.$store.dispatch('wAuth/forgotPassword', {email: this.credentials.email})
				this.$wToast.stopLoading()
				this.$wToast.success('Na uvedený email boli odoslane inštrukcie ohľadom resetovania hesla.')
				// this.$router.push(`${this.navigateToAfterForgotPassword}?email=${this.credentials.email}`)
			} catch (error) {
				this.$wToast.error(error)
			}
		}
	}
}
</script>
<style lang="scss" scoped>
        h1 {
            margin-bottom: 5px !important
        }

        h5 {
            margin-bottom: 30px
        }

				.callout {
					margin-bottom: 1rem;
					font-size: 0.875rem;
					color: red;
				}
</style>
